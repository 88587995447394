import { ErrorPage404 } from '@b7hio/core-lib/src';
import { GetStaticProps } from 'next';
import type { CustomTypeOptions } from 'react-i18next';
import { ssrTranslations } from '../hooks/ssr-translations';

export default function Custom404() {
  return <ErrorPage404 />;
}
export const getStaticProps: GetStaticProps = async (ctx) => {
  const tProps = await ssrTranslations(ctx, homeConfig.i18nNamespaces);

  return {
    props: {
      ...tProps,
    },
  };
};

export const homeConfig = {
  /** Namespaces that should be loaded for this page */
  i18nNamespaces: [
    'shell',
    'common',
    'home',
  ] as ReadonlyArray<keyof CustomTypeOptions['resources']>,
} as const;
